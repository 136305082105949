<template>
  <v-container fluid>
    <v-row class="login-container text-center full-height">
      <v-col class="login-container-card" cols="12">
        <v-col class="login-card">
          <div class="branding-container">
            <div class="branding">
              <img class="login-logo" src="@/assets/images/ph-logo-horizontal.svg" />
              <p class="login-system-title">Unified Service Portal</p>
            </div>
          </div>
          <br>
          <div class="ph-field-container">
            <div class="p-col-12 p-d-flex p-flex-column p-ai-center p-jc-center">
              <div class="login-page-icon-container">
                <img class="login-page-icon" v-bind:src="pageIconURL" />
              </div>
              <h1 class="reset-password-title">{{pageHeader}}</h1>
              <p class="reset-password-subtitle">{{pageSubheader}}</p>
              <!-- Main Form -->
              <v-col fluid cols="12" class="ph-textbox-container" v-if="showForm">
                <v-text-field
                  :error="(!hasEmailAddress) ? true : false"
                  v-model="emailAddress"
                  type="text"
                  placeholder="Email Address"
                  hide-details
                  outlined
                  class="ph-textbox">
                </v-text-field>
              </v-col>
              <v-col fluid cols="12" v-if="showForm" class="ph-action-buttons">
                <v-col fluid cols="12" class="ph-action-button">
                  <v-btn x-large color="#1A2791" elevation="0" class="ph-request-reset-button ph-login-button ph-login-reset-btn" @click="requestPasswordReset()">
                    Send Instructions
                  </v-btn>
                </v-col>
                <v-col fluid cols="12" class="ph-back-button-container">
                  <router-link to="/login" class="ph-back-button">
                    <img class="ph-back-logo" src="@/assets/images/icons/caret-back.svg" />
                    <p class="ph-back-text">Back to Login</p>
                  </router-link>
                </v-col>
              </v-col>

              <!-- Callback Form -->
              <div v-if="showCallback">
                <v-col fluid cols="12" class="ph-action-buttons">
                  <div class="p-col-12 ph-action-button">
                    <v-btn x-large color="#1A2791" elevation="0" class="ph-check-mail-button ph-login-button ph-login-reset-btn" @click="openMailApp()">
                      Open Mail App
                    </v-btn>
                  </div>
                  <div class="p-col-12 ph-back-button-container">
                    <router-link to="/login" class="ph-back-button">
                      <img class="ph-back-logo" src="@/assets/images/icons/caret-back.svg" />
                      <p class="ph-back-text">Skip, I'll confirm later</p>
                    </router-link>
                  </div>
                  <div class="p-col-12 ph-mail-disclaimer">
                    <p>Did not receive the email?<br>Check your spam filter or contact our support.</p>
                  </div>
                </v-col>
              </div>

              <div class="footer">
                <p>Copyright © {{getYear}} PERA HUB Unified Service Portal.  All rights reserved.</p>
              </div>
            </div>
          </div>
        </v-col>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { format } from 'date-fns'
import { renderToast } from '@/utils'

export default {
  name: 'ForgetPassword',
  data () {
    return {
      display: false,
      emailAddress: '',
      hasEmailAddress: '',
      hasProcessReset: false,
      hasVerifyRecaptcha: false,
      showForm: true,
      showCallback: false,
      pageIconURL: '',
      pageHeader: '',
      pageSubheader: '',
      getYear: format(new Date(), 'yyyy')
    }
  },
  watch: {
    showResetForm: {
      handler (isShow) {
        this.display = isShow
      },
      deep: true
    },
    showCallback: {
      handler (isShow) {
        this.displayMessage(isShow)
      },
      deep: true
    }
  },
  created () {
    this.displayMessage(this.showCallback)
  },
  methods: {
    renderToast,
    displayMessage (showCallback) {
      if (showCallback) {
        this.pageIconURL = require('@/assets/images/icons/mail-icon.svg')
        this.pageHeader = 'Check your email'
        this.pageSubheader = 'We have send a password recover instructions to your email.'
      } else {
        this.pageIconURL = require('@/assets/images/icons/keypass-icon.svg')
        this.pageHeader = 'Forgot password'
        this.pageSubheader = 'No worries, we\'ll send you reset instructions. '
      }
    },
    async requestPasswordReset () {
      const { emailAddress } = this

      const payload = {
        email_address: emailAddress
      }

      this.hasProcessReset = true

      this.hasEmailAddress = !((emailAddress === '' || emailAddress === null || emailAddress === undefined))

      if (!this.hasEmailAddress) {
        this.$emit('render-message', 'error', 'Invalid Input', 'The Email Address is required.')
        renderToast('error', 'Invalid Input', 'The Email Address is required.')
      } else {
        this.$store.commit('SET_LOADING', true)
        await this.$store.dispatch('doRequestPasswordReset', payload).then(res => {
          this.$store.commit('SET_LOADING', false)
          if (res.code === 200) {
            this.showCallback = true
            this.showForm = false
          } else {
            renderToast('error', 'Error', 'Something went wrong when requesting for Change Password. please try Again.')
          }
        }).catch(() => {
          renderToast('error', 'Error', 'Something went wrong when requesting for Change Password. please try Again.')
        })
      }
    },
    openMailApp () {
      // const emailAddress = this.emailAddress
      // const splitEmail = emailAddress.split('@')
      // const emailProvider = splitEmail[1]

      // if (emailProvider.includes('gmail') || emailProvider.includes('petnet.com.ph')) {
      //   window.open('gmail.com', '_blank')
      // } else {
      //   window.open('mail.yahoo.com', '_blank')
      // }

      window.open('https://gmail.com', '_blank')
    }
    // closeDialog () {
    //   this.display = false
    //   this.$emit('update:showResetForm', false)
    // }
    // recaptchaVerified (response) {
    //   if (response === null || response === undefined || response === '') {
    //     this.hasVerifyRecaptcha = false
    //   } else {
    //     this.hasVerifyRecaptcha = true
    //     localStorage.setItem('recaptcha_code', response)
    //   }
    // },
    // recaptchaExpired () {
    //   this.$refs.vueRecaptcha.reset()

    //   this.hasVerifyRecaptcha = false
    //   localStorage.removeItem('recaptcha_code')
    //   this.$emit('render-message', 'warn', 'ReCaptcha has Expired!', 'You must verify this again to continue.')
    // },
    // recaptchaFailed () {
    // }
  }
}
</script>
<style scoped>
  .login-logo {
    margin: auto;
    margin-bottom: 0;
    height: 25px;
    width: auto;
  }

  .login-inputs {
    margin-bottom: 25px;
  }

  .login-container-card {
    text-align: center;
  }

  .login-system-title {
    text-align: right;
    font-family: "Proxima Nova";
    font-size: 11.6px;
    color: #000;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    position: relative;
    bottom: 5px;
  }

  .branding {
    margin: auto;
    margin-top: 30px;
    height: 50px;
  }

  .branding-container {
    display: flex;
    margin-top: 0;
  }

  .reset-password-title {
    font-family: 'Baloo Extra Bold';
    font-size: 24px;
    font-weight: bolder;
    margin: 0 auto;
    margin-top: 15px;
    margin-bottom: 15px;
    color: #1A2791;
  }

  .reset-password-subtitle {
    font-size: 14px;
  }

  .ph-login-button {
    background: #FFF;
    color: #1A2791;
    /* border: 2px solid #1A2791; */
    border-radius: 10px;
    font-family: 'Proxima Nova';
    text-transform: uppercase;
    font-weight: 700;
    letter-spacing: 1px;
    font-size: 16px;
    padding: 23px;
    width: 100%;
    transition: .2s;
    height: 60px !important;
  }

  .ph-login-button:hover {
    background: #1A2791 !important;
    color: #fff !important;
    /* border: 2px solid #1A2791; */
  }

  .ph-login-button.ph-login-reset-btn {
    background: #1A2791;
    color: #fff;
    box-shadow: -4.75px 30px 30px 0px #1A279140 !important;
    font-size: 14px;
    z-index: 1;
  }

  .login-container-card .ph-back-button {
    color: #1A2791;
    display: flex;
    margin-top: 30px;
    text-transform: uppercase;
    text-decoration: none;
  }

  .ph-back-logo {
    margin-right: auto;
  }

  .ph-back-text {
    margin-right: auto;
    margin-top: auto;
    margin-bottom: auto;
    font-family: "Proxima Nova Bold" !important;
    text-decoration: none;
    font-size: 14px;
  }

  .ph-action-button {
    padding-top: 20px;
  }

  .ph-action-button,
  .ph-back-button {
    padding-left: 0;
    padding-right: 0;
  }

  .ph-mail-disclaimer {
    margin-top: 70px;
    font-size: 14px;
  }

  .footer {
    position: relative;
    top: 15px;
    font-size: 12px;
    text-align: center;
    margin: auto;
  }

  .ph-textbox-container {
    padding-top: 15px;
    padding-bottom: 0;
  }

  .login-card {
    margin: auto;
  }

@media only screen and (min-width: 960px) {
    .branding-container {
      height: 100%;
      margin-top: 50px;
    }

    .login-logo {
      height: 30px;
    }

    .login-system-title {
      font-size: 14px;
      position: relative;
      bottom: 5px;
    }

    .branding {
      margin-top: auto;
    }

    .footer {
      position: relative;
      top: 100px;
      text-align: center;
    }
  }

  @media only screen and (min-width: 500px) {
    .login-card {
      margin: auto;
      text-align: center;
      width: 395px;
      padding-top: 0;
      padding-bottom: 0;
      margin-top: 0;
    }
  }
</style>
